<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <v-card>
      <v-card-title
        >{{ $t("action.delete") }}{{ $_getLocale() == "zh" ? "" : " "
        }}{{
          $t("model.name." + this.model.name.singular.toLowerCase())
        }}</v-card-title
      >
      <v-card-text>
        <!-- BOC:[alert] -->
        <v-alert border="bottom" colored-border type="error" elevation="2">
          Are you sure you want to delete
          <b>{{
            me.name ? me.name : `${model.name.singular} ${$route.params.id}`
          }}</b
          >?
        </v-alert>
        <!-- EOC -->
        <!-- BOC:[form] -->
        <div class="text-right">
          <v-btn color="error" class="mr-4" @click="submit" text>
            {{ $t("action.yes") }}
          </v-btn>
          <v-btn text @click="$router.go(-1)">
            {{ $t("action.no") }}
          </v-btn>
        </div>
        <!-- EOC -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
//BOC:[model]
import modelGreatGrandParent from "@/models/items/book";
import modelGrandParent from "@/models/items/chapter";
import modelParent from "@/models/items/chapterQuestion";
import model from "@/models/items/chapterQuestionOption";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  props: ["greatGrandParent", "grandParent", "parent", "self"],
  data: () => ({
    //BOC:[role]
    role: "Moderator",
    //EOC
    //BOC:[model]
    modelGreatGrandParent: modelGreatGrandParent,
    modelGrandParent: modelGrandParent,
    modelParent: modelParent,
    model: model,
    //EOC
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[grand parent]
    myGrandParent: {
      id: null,
    },
    //BOC:[grand parent]
    myGreatGrandParent: {
      id: null,
    },
    //EOC
    //BOC:[parent]
    myParent: {
      id: null,
    },
    //EOC
    //BOC:[self]
    me: {
      id: null,
    },
    //EOC
    //BOC:[form]
    form: {},
    //EOC
  }),
  created() {
    //BOC:[guard]
    if (!this.model[this.role].delete) {
      this.$router.replace(
        this.$_getRouteRead(
          this.role,
          this.model.key,
          this.$route.params.id,
          this.self
        )
      );
      return;
    }
    //EOC
    //BOC:[parent]
    if (this.parent) this.myParent = this.$_.cloneDeep(this.parent);
    //EOC
    //BOC:[self]
    if (this.self) this.me = this.$_.cloneDeep(this.self);
    //EOC
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: this.$t("model.name.dashboard"),
      to: { name: "Page" + this.role + "Dashboard" },
      exact: true,
    });
    //
    this.breadcrumbs.push({
      text: this.$t(
        "model.name." + this.modelGreatGrandParent.name.plural.toLowerCase()
      ),
      to: this.$_getRouteBrowse(this.role, this.modelGreatGrandParent.key),
      exact: true,
    });
    //
    this.breadcrumbs.push({
      text:
        this.greatGrandParent && this.greatGrandParent.name
          ? this.greatGrandParent.name
          : `${this.$t(
              "model.name." +
                this.modelGreatGrandParent.name.singular.toLowerCase()
            )} ${this.$route.params.greatGrandParentId}`,
      to: this.$_getRouteRead(
        this.role,
        this.modelGreatGrandParent.key,
        this.$route.params.greatGrandParentId,
        this.greatGrandParent
      ),
      exact: true,
    });
    //
    let greatGrandParentPath = this.$_.cloneDeep(
      this.breadcrumbs[this.breadcrumbs.length - 1].to
    );
    greatGrandParentPath.query = { tab: "Chapter" };
    //
    this.breadcrumbs.push({
      text: this.$t(
        "model.name." + this.modelGrandParent.name.plural.toLowerCase()
      ),
      to: greatGrandParentPath,
      exact: true,
    });
    this.breadcrumbs.push({
      text:
        this.grandParent && this.grandParent.name
          ? this.grandParent.name
          : `${this.$t(
              "model.name." + this.modelGrandParent.name.singular.toLowerCase()
            )} ${this.$route.params.grandParentId}`,
      to: this.$_getRouteChildRead(
        this.role,
        this.modelGrandParent.key,
        this.$route.params.grandParentId,
        this.grandParent,
        this.modelGreatGrandParent.key,
        this.$route.params.greatGrandParentId,
        this.greatGrandParent
      ),
      exact: true,
    });
    //
    let path2 = this.$_.cloneDeep(
      this.breadcrumbs[this.breadcrumbs.length - 1].to
    );
    path2.query = { tab: "ChapterQuestion" };
    this.breadcrumbs.push({
      text: this.$t("model.name." + this.modelParent.name.plural.toLowerCase()),
      to: path2,
      exact: true,
    });
    //
    //
    this.breadcrumbs.push({
      text:
        this.myParent && this.myParent.name
          ? this.myParent.name
          : `${this.$t(
              "model.name." + this.modelParent.name.singular.toLowerCase()
            )} ${this.$route.params.parentId}`,
      to: {
        name: "PageModeratorBookChapterChapterQuestionRead",
        params: {
          grandParentId: this.$route.params.greatGrandParentId,
          parentId: this.$route.params.grandParentId,
          id: this.$route.params.parentId,
        },
      },
      exact: true,
    });
    //
    let path = this.$_.cloneDeep(
      this.breadcrumbs[this.breadcrumbs.length - 1].to
    );
    path.query = { tab: "QuestionOption" };
    this.breadcrumbs.push({
      text: this.$t("model.name." + this.model.name.plural.toLowerCase()),
      to: path,
      exact: true,
    });
    //
    //
    this.breadcrumbs.push({
      text: this.me.name
        ? this.me.name
        : `${this.$t("model.name." + this.model.name.singular.toLowerCase())} ${
            this.$route.params.id
          }`,
      to: {
        name: "PageModeratorBookChapterChapterQuestionChapterQuestionOptionRead",
      },
      exact: true,
    });
    //EOC

    this.breadcrumbs.push({
      text: this.$t("action.delete"),
      to: this.$_getRouteGreatGrandChildDelete(
        this.role,
        this.model.key,
        this.$route.params.id,
        this.self,
        this.modelParent.key,
        this.$route.params.parentId,
        this.myParent,
        this.modelGrandParent.key,
        this.$route.params.grandParentId,
        this.myGrandParent,
        this.modelGreatGrandParent.key,
        this.$route.params.greatGrandParentId,
        this.myGreatGrandParent
      ),
      exact: true,
    });

    //
    //EOC
    //BOC:[url]
    this.api.url = `${this.$api.servers.game}/api/v1/en/moderator/book/${this.$route.params.greatGrandParentId}/chapter/${this.$route.params.grandParentId}/question/${this.$route.params.parentId}/option/delete`;

    //EOC
    //BOC:[api]
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = () => {
      this.api.isLoading = false;
      let redirect = { name: "PageModeratorBookChapterChapterQuestionRead",params:{grandParentId:this.$route.params.greatGrandParentId, parentId: this.$route.params.grandParentId,id:this.$route.params.parentId} }
      redirect.query = {tab:'QuestionOption'}
      this.$router.push(redirect);
    };
    //EOC
    //BOC:[form]
    this.form.id = this.$route.params.id;
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //BOC:[form]
    submit() {
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    },
    //EOC
  },
};
</script>