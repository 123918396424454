import i18n from "@/plugins/i18n";
export default {
  browse: {
    table: {
      headers: [
        {
          text: i18n.t("model.prop.no"),
          value: "index",
          sortable: false,
          filterable: false,
        },
        {
          text: "Name",
          value: "translatableName",
        },
        {
          text: "Minimum stars to unlock",
          value: "minStarToUnlock",
        },
      
      ],
    },
  },
  read: {
    tabs: [
      {
        key: "Page",
        label: 'Page',
      },
      {
        key: "ChapterStep",
        label: 'Step',
      },
      {
        key: "ChapterQuestion",
        label: 'Question',
      },
      {
        key: "Info",
        label: i18n.t("string.info"),
      },
     
    ],
    table: {
      headers: [
        {
          text: i18n.t("model.prop.no"),
          value: "index",
          sortable: false,
          filterable: false,
        },
        {
          text: "Name",
          value: "translatableName",
        },
        {
          text: "Section",
          value: "translatableSection",
        },
        {
          text: "Minimum stars to unlock",
          value: "minStarToUnlock",
        },
        {
          text: i18n.t("model.prop.created"),
          value: "timestampCreated",
          filterable: false,
        },
        {
          text: i18n.t("model.prop.lastUpdated"),
          value: "timestampUpdated",
          filterable: false,
        },
      ],
    },
  },
  add:{
    fields:[
      {
        type:'string',
        key:'translatableName',
        name:'Chapter Name',
        defaultValue:'{"en":"Example Chapter","zh":"示例章节","ms":"Contoh Bab"}'
      },
      {
        type:'string',
        key:'translatableSection',
        name:'Chapter Section',
        defaultValue:'{"en":"Example Chapter Section","zh":"示例章节","ms":"Contoh Bahagian Bab"}'
      },
      {
        type:'number',
        key:'sort',
        name:'sort',
        min:1,
        defaultValue:1,
      },
      {
        type:'string',
        key:'key',
        name:'Key',
      },
      {
        type:'number',
        key:'minStarToUnlock',
        name:'Minimum stars to unlock',
        min:0,
        defaultValue:0,
      },
    ],
    rules:{
      translatableName: [v => !!v || 'Chapter name is required'],
      translatableSection: [v => !!v || 'Chapter section is required'],
      sort: [v => !!v || 'Sort is required'],
      key: [v => !!v || 'Key is required'],
    },
  },
  edit:true,
  delete: true,
};
