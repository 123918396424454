
import Moderator from '@/models/bread/Moderator/chapterQuestion'


export default {
  key:'ChapterQuestion',
  name:{
    singular:'Question',
    plural:'Questions',
  },
  parents:[
    'Chapter',
  ],
  grandParents:[
    'Book',
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}