import Moderator from '@/models/bread/Moderator/chapter'

export default {
  key:'Chapter',
  name:{
    singular:'Chapter',
    plural:'Chapters',
  },
  parents:[
    'Book'
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}