
import Moderator from '@/models/bread/Moderator/chapterQuestionOption'


export default {
  key:'ChapterQuestionOption',
  name:{
    singular:'Option',
    plural:'Options',
  },
  parents:[
    'Page',
  ],
  grandParents:[
    'Chapter',
  ],
  greatGrandParents:[
    'Book'
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}